import type { LegacyRDMDOpts, RMDXOpts, Variables } from '@readme/iso/src/types/rdmd';

import { createCustomBlocksHash } from '@readme/iso';
import { useContext, useMemo } from 'react';

import { ThemeContext } from '@Hub/Theme';

import { ProjectContext, VariablesContext, type ProjectContextValue } from '@core/context';
import useMediaQuery from '@core/hooks/useMediaQuery';
import { useProjectStore, useSuperHubStore } from '@core/store';

/**
 * Constructs the options object used by the RDMD renderer.
 * When a project is mdx/superhub enabled, the options are kept in sync with
 * the current custom blocks and glossary terms in state.
 *
 * @example
 * const rdmdOpts = useRdmdOpts();
 * <RDMD opts={rdmdOpts} />
 */
export default function useRdmdOpts() {
  const variables = useContext(VariablesContext) as Variables;
  const { project } = useContext(ProjectContext) as ProjectContextValue;

  const [isMdx, terms] = useProjectStore(s => [s.data.features.mdx === 'enabled', s.data.glossary]);
  const customBlocks = useSuperHubStore(s => s.document.customBlocks);

  // Derive project theme to pass to the RDMD contexts
  const { colorMode } = useContext(ThemeContext); // initialized to project theme but tracks overrides via light/dark mode toggle
  const systemTheme = useMediaQuery('(prefers-color-scheme: dark)') ? 'dark' : 'light';
  const theme = colorMode === 'system' ? systemTheme : colorMode;

  // Use when rendering MDX content
  const mdxOpts: RMDXOpts = useMemo(
    () => ({
      components: createCustomBlocksHash(customBlocks),
      terms,
      theme,
      variables,
    }),
    [customBlocks, terms, theme, variables],
  );

  // Use when rendering legacy Markdown content
  const legacyOpts: LegacyRDMDOpts = useMemo(
    () => ({
      compatibilityMode: !!project?.flags?.rdmdCompatibilityMode,
      correctnewlines: !!project?.flags?.correctnewlines,
      normalize: true,
      terms,
      theme,
      variables,
    }),
    [project?.flags?.correctnewlines, project?.flags?.rdmdCompatibilityMode, terms, theme, variables],
  );

  return isMdx ? mdxOpts : legacyOpts;
}
